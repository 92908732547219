import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedCategory: null,
    selectedService: null  
  },
  getters: {
  },
  mutations: {
    setSelectedCategory(state, category) {
        state.selectedCategory = category;
      },
      setSelectedService(state, service) {
        state.selectedService = service;
      }
  },
  actions: {
  },
  modules: {
  }
})
